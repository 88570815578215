<template>
   <div>
    <b-card>
      <table-render
        v-if="!loading.first"
        id="table_historyList"
        :rows="rows"
        :schema="schema"
        :loading="loading.history"
        :sortByCustom="orderBy"
      >
        <template #crudType="scope">
          <b-badge :variant="scope.rowdata.crudClass">{{scope.rowdata.crudType}}</b-badge>
        </template>
        <template #event_user="scope">
          <p>{{scope.rowdata.event_user}}</p>
        </template>
        <template #contactEmails="scope">
          <p class="mb-0 text-start"
             v-for="(contact, index) in scope.rowdata.contacts"
             :key="contact.email"
             v-b-tooltip.html.hover.top="getTooltipContent(contact)">
            {{ contact.email }}
            <span v-show="index !== scope.rowdata.contacts.length - 1">,</span>
          </p>
        </template>
        <template #requireOC="scope">
          <b-badge :variant="scope.rowdata.ocClass">{{scope.rowdata.requireOC}}</b-badge>
        </template>
        <template #directBilling="scope">
          <b-badge :variant="scope.rowdata.directBillingClass">{{scope.rowdata.directBilling}}</b-badge>
        </template>
        <template #showInvoices="scope">
          <b-badge :variant="scope.rowdata.showInvoices ? 'success' : 'secondary'">{{scope.rowdata.showInvoices}}</b-badge>
        </template>
      </table-render>
      <pagination
          v-if="!loading.first"
          :pagination="pagination"
          :noDigits="false"
          :showSize="true"
          :disabled="loading.history"
      />
      <b-skeleton
          height="40px"
          width="100%"
          class="mt-2 mb-2 spacing-label-field"
          v-show="loading.first"
      />
      <div class="table-render-skeleton" v-show="loading.first">
          <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{}"
          />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'manage-edit-history',
  data() {
    return {
      dataHistory: '',
      loading: {
        first: true,
        history: true
      },
      rows: [],
      actionsCrud: {},
      schema: [],
      paramId: null,
      pagination: { page: 1, limit: 10, total: 0, loading: false },
      sortProps: null
    }
  },
  computed: {
    ...mapGetters({
      manageDataHistory: 'getManageDataCompanyHistory'
    })
  },
  watch: {
    manageDataHistory() {
      if (this.manageDataHistory.rows.length === 0) this.$info(this.$t('msg-alerta-no-edit-manageData-history'), () => null, 'Aviso')
      const actionType = this.valueToKeyObject(this.manageDataHistory.events.types)
      const actionSouces = this.valueToKeyObject(this.manageDataHistory.events.sources)
      this.rows = this.manageDataHistory.rows.map(dataHistory => {
        return {
          ...dataHistory,
          updatedAt: this.$options.filters.dbDateUTCToGMTlocal(dataHistory.event_timestamp),
          crudType: actionType[dataHistory.event_type_id],
          crudClass: this.getStatusClass(dataHistory.event_type_id),
          crudSource: actionSouces[dataHistory.event_source_id],
          mailsData: dataHistory.contacts?.map(e => e.email)?.join(', '),
          ocClass: this.getStatusClass(dataHistory.requireOC),
          directBillingClass: this.getStatusClass(dataHistory.directBilling)
        }
      })
      this.loading.first = false
      this.loading.history = false
      this.pagination.page = this.manageDataHistory.pagination.current_page
      this.pagination.total = this.manageDataHistory.pagination.total_items
      this['pagination.limit'] = this.manageDataHistory.pagination.total_pages
    },
    'pagination.page'() {
      this.getHistoryData()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getHistoryData()
    }
  },
  mounted() {
    this.paramId = this.$route.params.id
    this.setInitialData()
    this.getHistoryData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'Acción', key: 'crudType', useSlot: true },
        { label: 'Tipo de carga', key: 'crudSource', customSort: 'event_source_id' },
        { label: 'Usuario', key: 'event_user', class: 'col_width', useSlot: true, customSort: 'event_user' },
        { label: 'Fecha', key: 'updatedAt', customSort: 'event_timestamp' },
        { label: 'TaxId', key: 'taxIdentificationNumber' },
        { label: 'Razón social', key: 'businessName' },
        { label: 'Días de crédito', key: 'creditDays' },
        { label: 'Giro', key: 'businessActivity'},
        { label: 'OC', key: 'requireOC', useSlot: true },
        { label: 'Facturación Directa', key: 'directBilling', useSlot: true },
        { label: 'Mostrar facturas', key: 'showInvoices', useSlot: true },
        { label: 'Fecha límite de pago', key: 'overduePaymentLimit' },
        { label: 'Emails de contacto', key: 'contactEmails', class: 'col_width', useSlot: true },
        { label: 'Dirección', key: 'address' },
        { label: 'Comuna', key: 'placeName'},
        { label: 'Cuidad', key: 'city' }
      ]
    },
    getTooltipContent(contact) {
      return `<div class="tooltip-content-left">
        <p>${contact.email}</p>
        <p>Nombre: ${contact.name ?? '-'}</p>
        <p>Teléfono: ${contact.phoneNumber ?? '-'}</p>
        <p>Cargo: ${contact.jobTitle ?? '-'}</p>
      </div>`
    },
    orderBy (sortAs, type) {
      this.sortProps = type ? type === 'desc' ? `-${sortAs}` : sortAs : null
      this.getHistoryData()
    },
    getHistoryData() {
      this.loading.history = true
      const queryParams = {
        page: this.pagination.page,
        size: this.pagination.limit
      }
      const params = {
        company_id: this.paramId
      }
      if (this.sortProps) queryParams.sort = this.sortProps
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'getManageDataCompanyHistory', queryParams, params, onError: err => this.handleErrorHIstory(err)})
    },
    handleErrorHIstory(error) {
      this.loading.history = false
      const {subtitle, detail} = this.handlerStandartError(error)
      this.$alert(`${subtitle}<small>${detail}</small>`, null, 'Error al obtener el historial de la compañía')
    },
    getStatusClass (id) {
      switch (id) {
      case (1 || false): // creado o eliminada
        return 'secondary'
      case 2: // Por vencer
        return 'primary'
      case 3: // borrado
        return 'danger'
      case true: // activa
        return 'success'
      default:
        return ''
      }
    },
    valueToKeyObject(obj) {
      if (!obj) return
      const objParsed = {}
      obj.forEach(val => {
        objParsed[val['id']] = val['name']
      })
      return objParsed
    }
  }
}
</script>

<style lang="scss">

.col_width p{
  max-width: 130px;
  margin-bottom: 0;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Espaciado entre contactos */
}

.contact-item {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
}

.contact-item p {
  margin: 0;
  line-height: 1.5;
}

.contact-item strong {
  color: #007bff;
}

.tooltip-content-left {
  text-align: left;

  p {
    margin: 0;
    padding: 2px 0;
  }
}

// Estilos globales para el tooltip (fuera del scope si es necesario)
::v-deep .tooltip-inner {
  text-align: left;
  max-width: 300px; // Ajusta según sea necesario
}
</style>
